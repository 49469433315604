import React from 'react';
import Heading from '../components/heading';
import BrowseStackshare from '../components/browse-stackshare';
import SecondaryFooter from '../../site/components/second-footer/index.jsx';
import {sections} from '../../../data/landing/constants';
import {useSendAnalyticsEvent} from '../../../shared/enhancers/analytics-enhancer';
import MillionDevelopers from '../components/million-developers';
import StackshareInTheNews from '../components/stackshare-in-the-news';

const LandingComponent = () => {
  const sendAnalyticsEvent = useSendAnalyticsEvent();

  return (
    <>
      <Heading />
      <MillionDevelopers />
      <BrowseStackshare sendAnalyticsEvent={sendAnalyticsEvent} />
      <StackshareInTheNews sendAnalyticsEvent={sendAnalyticsEvent} />
      <SecondaryFooter sections={sections} sendAnalyticsEvent={sendAnalyticsEvent} />
    </>
  );
};

export default LandingComponent;
