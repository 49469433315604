import glamorous from 'glamorous';
import {MAX_PAGE_WIDTH} from '../../../../shared/style/dimensions';
import {BLACK, WHITE} from '../../../../shared/style/colors';
import {BASE_TEXT} from '../../../../shared/style/typography';
import {PHONE, TABLET} from '../../../../shared/style/breakpoints';

const Container = glamorous.div(({background = WHITE, maxWidth = '100%'}) => ({
  width: '100%',
  maxWidth: maxWidth,
  background: background,
  padding: '0 15px',
  [TABLET]: {
    width: 'auto'
  }
}));

const SubContainer = glamorous.div(
  ({maxWidth = MAX_PAGE_WIDTH, padding = 0}) => ({
    ...BASE_TEXT,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
    maxWidth: maxWidth,
    margin: '0 auto',
    padding: padding
  }),
  ({customStyle}) => (customStyle ? customStyle : {})
);

const H3Title = glamorous.h3(
  ({maxWidth = '100%', textAlign = 'left', margin = 0, padding = 0}) => ({
    width: '100%',
    maxWidth: maxWidth,
    fontSize: 40,
    fontWeight: 600,
    lineHeight: 1.36,
    textAlign: textAlign,
    color: BLACK,
    margin: margin,
    padding: padding,
    [PHONE]: {
      fontSize: 28
    }
  })
);

const H4Title = glamorous.h4(({maxWidth = '100%'}) => ({
  maxWidth: maxWidth,
  fontSize: 20,
  lineHeight: 1.95,
  color: '#363636',
  [PHONE]: {
    fontWeight: 400
  }
}));

export {Container, SubContainer, H3Title, H4Title};
