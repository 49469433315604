import React from 'react';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import {WHITE, CHARCOAL, BLACK} from '../../../shared/style/colors';
import {PHONE, TABLET, PHONE_LANDSCAPE, LAPTOP, DESKTOP} from '../../../shared/style/breakpoints';
import {BASE_TEXT, FONTS, WEIGHT} from '../../../shared/style/typography';
import {
  CLOUD_100,
  FORBES,
  HOMEPAGE_CLICK_FEATURED,
  NEW_YORK_TIMES,
  TECH_CRUNCH
} from './common/constants';
import TechcrunchImage from '../images/techcrunch.svg';
import ForbesImage from '../images/forbes.svg';
import NewYorkTimesImage from '../images/new-york-times.svg';
import Cloud100Image from '../images/cloud100.svg';
import {MAX_PAGE_WIDTH} from '../../../shared/style/dimensions';

const Container = glamorous.div(({background = WHITE}) => ({
  display: 'block',
  marginBottom: 70,
  background: background,
  padding: '0 20px',
  [DESKTOP]: {
    padding: 0,
    maxWidth: MAX_PAGE_WIDTH,
    margin: 'auto',
    marginBottom: 70
  },
  [TABLET]: {
    marginBottom: 0
  }
}));

const Title = glamorous.div({
  ...BASE_TEXT,
  fontFamily: FONTS.CEREBRI_SANS_PRO,
  fontWeight: WEIGHT.ULTRA_BLACK,
  fontSize: 40,
  textAlign: 'center',
  color: CHARCOAL,
  marginBottom: 25,
  [PHONE]: {
    fontSize: 28,
    lineHeight: '35px',
    textAlign: 'center',
    margin: 'auto'
  },
  [LAPTOP]: {
    fontSize: 34
  }
});

const FeaturedIn = glamorous.div({
  backgroundColor: WHITE,
  [LAPTOP]: {
    padding: '25px 0',
    textAlign: 'center'
  },
  [PHONE_LANDSCAPE]: {
    padding: '35px 25px',
    paddingTop: 0
  },
  [PHONE]: {
    padding: '54px 0 35px 0'
  },
  '> div': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 30,
    flexWrap: 'wrap',
    [PHONE_LANDSCAPE]: {
      paddingBottom: 20,
      paddingTop: 20
    },
    [LAPTOP]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridRowGap: 25
    },
    [PHONE]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: 20
    }
  }
});

const Techcrunch = glamorous(TechcrunchImage)({
  width: '100%',
  maxWidth: 88,
  height: 'auto',
  cursor: 'pointer',
  [DESKTOP]: {
    ':hover': {
      '> g': {
        fill: '#0c9c03'
      }
    }
  },
  [PHONE_LANDSCAPE]: {
    maxWidth: 65,
    marginTop: 20
  },
  [PHONE]: {
    width: 89,
    height: 45
  }
});

const NewYorkTimes = glamorous(NewYorkTimesImage)({
  width: '100%',
  maxWidth: 290,
  height: 'auto',
  cursor: 'pointer',
  [DESKTOP]: {
    ':hover': {
      '> g': {
        fill: '#221f1f'
      }
    }
  },
  [PHONE_LANDSCAPE]: {
    maxWidth: 185,
    marginTop: 20
  },
  [PHONE]: {
    width: 292,
    height: 42
  }
});

const Forbes = glamorous(ForbesImage)({
  width: '100%',
  maxWidth: 152,
  height: 'auto',
  cursor: 'pointer',
  [DESKTOP]: {
    ':hover': {
      '> path': {
        fill: BLACK
      }
    }
  },
  [PHONE_LANDSCAPE]: {
    maxWidth: 130,
    marginTop: 20
  },
  [PHONE]: {
    width: 153,
    height: 38
  }
});

const Cloud100 = glamorous(Cloud100Image)({
  width: '100%',
  maxWidth: 100,
  height: 'auto',
  cursor: 'pointer',
  [DESKTOP]: {
    ':hover': {
      '> path': {
        fill: '#2672e7'
      }
    }
  },
  [PHONE_LANDSCAPE]: {
    maxWidth: 83
  },
  [PHONE]: {
    width: 124,
    height: 78
  }
});

const newsArticles = [
  {
    icon: Techcrunch,
    analyticsName: TECH_CRUNCH,
    link:
      'https://techcrunch.com/2018/01/09/developer-social-network-stackshare-raises-5-2-million-from-e-ventures/?ref=stackshare'
  },
  {
    icon: Forbes,
    analyticsName: FORBES,
    link:
      'https://www.forbes.com/sites/amyguttman/2019/05/24/meet-a-founder-transforming-how-software-is-bought/#64b17f844371'
  },
  {
    icon: NewYorkTimes,
    analyticsName: NEW_YORK_TIMES,
    link:
      'https://www.nytimes.com/2017/04/11/magazine/new-technology-is-built-on-a-stack-is-that-the-best-way-to-understand-everything-else-too.html?ref=stackshare'
  },
  {
    icon: Cloud100,
    analyticsName: CLOUD_100,
    link:
      'https://www.forbes.com/sites/kenrickcai/2019/09/11/the-cloud-100-rising-stars-2019-meet-the-20-up-and-comers-in-techs-hottest-category/#487e1bed2ad4'
  }
];

const StackshareInTheNews = ({sendAnalyticsEvent}) => {
  return (
    <Container>
      <Title>StackShare in the news</Title>
      <FeaturedIn>
        <div>
          {newsArticles.map((item, index) => (
            <a
              href={item.link}
              key={index}
              onClick={() =>
                sendAnalyticsEvent(HOMEPAGE_CLICK_FEATURED, {
                  external_url: item.link,
                  type: item.analyticsName
                })
              }
              style={{marginBottom: 40}}
            >
              <item.icon />
            </a>
          ))}
        </div>
      </FeaturedIn>
    </Container>
  );
};

StackshareInTheNews.propTypes = {
  sendAnalyticsEvent: PropTypes.func
};

export default StackshareInTheNews;
