import {SIGN_IN_ROUTE, SLUG_CAPTURE} from '../../../shared/constants/routes';
const slug = match => ({
  slug: match[1]
});

export const routes = {
  [SIGN_IN_ROUTE]: (match, props, prevRouteContext) => ({
    ...prevRouteContext,
    signin: true
  }),
  [`^/home/${SLUG_CAPTURE}`]: slug
};
