export const HOMEPAGE_CLICK_LEARN_MORE = 'homepage_click_learn_more';
export const HOMEPAGE_CLICK_FEATURED = 'homepage_click_featured';
export const HOMEPAGE_CLICK_BROWSE = 'homepage_click_browse';
export const HOMEPAGE_CLICK_EXPLORE = 'homepage_click_explore';

export const MAIN = 'main';
export const DASHBOARD = 'dashboard';
export const ALERTS = 'alerts';
export const DECISIONS = 'decisions';
export const ADVICE = 'advice';
export const DATA = 'data';

export const TECH_CRUNCH = 'techcrunch';
export const NEW_YORK_TIMES = 'newyorktimes';
export const FORBES = 'forbes';
export const CLOUD_100 = 'cloud100';

export const FEED = 'feed';
export const TOOLS = 'tools';
export const STACKS = 'stacks';
export const STACKUPS = 'stackups';

export const PUBLIC = 'public';
export const PRIVATE = 'private';
