import React from 'react';
import glamorous from 'glamorous';
import {WHITE, CHARCOAL, DODGER_BLUE} from '../../../shared/style/colors';
import {PHONE_LANDSCAPE, PHONE, DESKTOP, LAPTOP, MEDIUM} from '../../../shared/style/breakpoints';
import SimpleButton from '../../../shared/library/buttons/base/simple';
import {FONTS, WEIGHT} from '../../../shared/style/typography';
import {MAX_PAGE_WIDTH} from '../../../shared/style/dimensions';

const Container = glamorous.div(({background = WHITE}) => ({
  display: 'block',
  background: background,
  marginBottom: 140,
  padding: '0 20px',
  [DESKTOP]: {
    padding: 0,
    maxWidth: MAX_PAGE_WIDTH,
    margin: 'auto',
    marginBottom: 140,
    display: 'flex'
  },
  [PHONE]: {
    height: 500,
    marginLeft: 0,
    marginBottom: 80
  },
  [MEDIUM]: {
    display: 'flex'
  },
  [LAPTOP]: {
    display: 'block',
    textAlign: 'center'
  },
  '> img': {
    [PHONE]: {
      width: 350,
      height: 243
    }
  }
}));

const Title = glamorous.h3({
  fontFamily: FONTS.CEREBRI_SANS_PRO,
  fontSize: 44,
  fontWeight: WEIGHT.ULTRA_BLACK,
  color: CHARCOAL,
  lineHeight: '53px',
  alignSelf: 'center',
  textAlign: 'left',
  margin: '0 0 48px 0',
  [PHONE]: {
    fontSize: 28,
    lineHeight: '40px',
    textAlign: 'center',
    margin: 'auto',
    marginBottom: 30
  },
  [LAPTOP]: {
    margin: 'auto',
    marginBottom: 30,
    textAlign: 'center'
  }
});

const ExploreCommunity = glamorous(SimpleButton)({
  fontFamily: FONTS.CEREBRI_SANS_PRO,
  minHeight: 56,
  background: DODGER_BLUE,
  borderRadius: 4,
  fontSize: 19,
  fontWeight: WEIGHT.ULTRA_BOLD,
  lineHeight: '39px',
  [PHONE_LANDSCAPE]: {
    margin: 'auto'
  },
  [LAPTOP]: {
    width: 446,
    margin: 'auto',
    paddingLeft: 0,
    paddingRight: 0
  },
  [PHONE]: {
    width: 'auto',
    padding: 0,
    fontSize: 18,
    fontWeight: WEIGHT.BOLD,
    lineHeight: '68px',
    margin: 'auto'
  },

  ':focus': {
    color: WHITE
  }
}).withComponent('a');

const TextButtonContainer = glamorous.div({
  alignSelf: 'center',
  marginTop: 27,
  [DESKTOP]: {
    marginLeft: 85
  },
  [LAPTOP]: {
    margin: 'auto',
    marginTop: 27
  },
  [MEDIUM]: {
    marginLeft: 50,
    marginRight: 30
  },
  [PHONE]: {
    marginLeft: 0,
    marginRight: 0
  }
});

const MillionDevIcon = glamorous.img({
  [LAPTOP]: {
    width: '100%'
  }
});

const millionDeveloperIcon = 'https://img.stackshare.io/fe/landing/1Mdevs.svg';

const MillionDevelopers = () => {
  return (
    <Container background={WHITE}>
      <MillionDevIcon src={millionDeveloperIcon} />
      <TextButtonContainer>
        <Title>StackShare delivers tech stack insights to over one million developers</Title>
        <ExploreCommunity href={`/tools/trending`}>Explore StackShare Community</ExploreCommunity>
      </TextButtonContainer>
    </Container>
  );
};

export default MillionDevelopers;
