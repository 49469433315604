import React from 'react';
export const LandingContext = React.createContext(false);

const withLanding = Component => ({...restProps}) => {
  const context = {};

  return (
    <LandingContext.Provider value={context}>
      <Component {...restProps} />
    </LandingContext.Provider>
  );
};

export default withLanding;
