import React from 'react';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import {PHONE, LAPTOP, DESKTOP, MEDIUM} from '../../../shared/style/breakpoints';
import SimpleButton from '../../../shared/library/buttons/base/simple';
import {BLACK, CHARCOAL, DODGER_BLUE, LIGHT_SHADED_GREY, WHITE} from '../../../shared/style/colors';
import {SubContainer} from './common/components';
import {HOMEPAGE_CLICK_BROWSE, STACKS, STACKUPS, TOOLS} from './common/constants';
import {BASE_TEXT, FONTS, WEIGHT} from '../../../shared/style/typography';
import {MAX_PAGE_WIDTH} from '../../../shared/style/dimensions';

const Container = glamorous.div(({background = WHITE}) => ({
  background: background,
  marginBottom: 60,
  padding: '0 20px',
  [DESKTOP]: {
    maxWidth: MAX_PAGE_WIDTH,
    margin: 'auto',
    padding: 0,
    marginBottom: 60
  },
  [PHONE]: {
    marginBottom: 40
  }
}));

const DetailsDiv = glamorous.div({
  alignItems: 'center',
  justifyContent: 'space-between',
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridColumnGap: '20px',
  [LAPTOP]: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    justifyItems: 'center',
    gap: '26px 30px',
    marginTop: 0
  },
  [PHONE]: {
    display: 'block',
    marginTop: 54
  },
  [MEDIUM]: {
    justifyContent: 'space-evenly'
  }
});

const details = [
  {
    imgLink: 'https://img.stackshare.io/fe/private/explore-tools.png',
    analyticsName: TOOLS,
    customStyle: {
      maxWidth: 206,
      height: 162,
      marginTop: 30,
      marginBottom: 33
    },
    desc: 'Explore popular and trending tools',
    link: '/tools',
    buttonName: 'Explore Tools'
  },
  {
    imgLink: 'https://img.stackshare.io/fe/private/browse-stacks.png',
    analyticsName: STACKS,
    customStyle: {
      maxWidth: 207,
      height: 132,
      marginTop: 42,
      marginBottom: 50
    },
    desc: 'Browse public stacks from top companies',
    link: '/stacks',
    buttonName: 'Browse Stacks'
  },
  {
    imgLink: 'https://img.stackshare.io/fe/private/comparisons.png',
    analyticsName: STACKUPS,
    customStyle: {
      maxWidth: 217,
      height: 136,
      marginTop: 51,
      marginBottom: 37
    },
    desc: 'Compare tools side-by-side',
    link: '/stackups',
    buttonName: 'Compare Tools'
  }
];

const Button = glamorous(SimpleButton)({
  ...BASE_TEXT,
  fontFamily: FONTS.CEREBRI_SANS_PRO,
  fontSize: 18,
  fontWeight: WEIGHT.ULTRA_BOLD,
  textAlign: 'center',
  padding: 0,
  borderRadius: 5,
  marginTop: 35,
  ':focus': {
    color: WHITE
  },
  [DESKTOP]: {
    width: 254,
    height: 50,
    marginLeft: 15,
    marginRight: 15
  },
  [LAPTOP]: {
    width: 254,
    height: 50
  },
  [PHONE]: {
    width: 212,
    height: 42,
    fontSize: 18,
    lineHeight: '18px',
    marginTop: 30
  }
}).withComponent('a');

const Card = glamorous.div({
  height: 400,
  borderRadius: 6,
  border: `1px solid ${LIGHT_SHADED_GREY}`,
  boxShadow: '0 15px 25px 0 rgba(0, 0, 0, 0.05), 0 0 12px 0 rgba(0, 0, 0, 0.04)',
  backgroundColor: WHITE,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '> div': {
    margin: 0,
    fontFamily: FONTS.CEREBRI_SANS_PRO,
    width: '100%',
    maxWidth: 240,
    fontSize: 20,
    fontWeight: WEIGHT.BOLD,
    lineHeight: 1.35,
    letterSpacing: 0.4,
    textAlign: 'center',
    color: BLACK
  },
  [LAPTOP]: {
    width: '100%'
  },
  [PHONE]: {
    marginBottom: 18
  }
});

const CardImage = glamorous.img(
  {
    width: '100%',
    height: 'auto'
  },
  ({customStyle}) => (customStyle ? customStyle : {})
);

const subContainerStyle = {
  paddingBottom: 60,
  [PHONE]: {
    paddingBottom: 0
  },
  '> h3': {
    [LAPTOP]: {
      padding: '110px 0 60px 0',
      maxWidth: 630
    }
  }
};

const Title = glamorous.h2({
  ...BASE_TEXT,
  fontFamily: FONTS.CEREBRI_SANS_PRO,
  fontSize: 40,
  fontStyle: 'normal',
  color: CHARCOAL,
  fontWeight: WEIGHT.ULTRA_BLACK,
  lineHeight: '50px',
  alignSelf: 'center',
  textAlign: 'center',
  margin: 0,
  [PHONE]: {
    fontSize: 28,
    lineHeight: '40px',
    textAlign: 'center'
  },
  [LAPTOP]: {
    fontSize: 34
  }
});

const TitleTag = glamorous.h3({
  ...BASE_TEXT,
  fontFamily: FONTS.CEREBRI_SANS_PRO,
  textAlign: 'center',
  textTransform: 'uppercase',
  lineHeight: '53px',
  marginBottom: 0,
  fontSize: 21,
  fontWeight: WEIGHT.ULTRA_BLACK,
  fontStyle: 'normal',
  letterSpacing: 2,
  color: DODGER_BLUE,
  [PHONE]: {
    height: 40,
    fontSize: 16,
    textAlign: 'center',
    margin: 'auto'
  },
  [LAPTOP]: {
    fontSize: 18
  }
});

const TextContainer = glamorous.div({
  marginBottom: 60
});

const BrowseStackshare = ({sendAnalyticsEvent}) => {
  return (
    <Container id="public-features">
      <SubContainer customStyle={subContainerStyle}>
        <TextContainer>
          <TitleTag>Community</TitleTag>
          <Title>Insights from the StackShare community</Title>
        </TextContainer>
        <DetailsDiv>
          {details.map((detail, index) => (
            <Card key={index}>
              <CardImage
                src={detail.imgLink}
                width={detail.width}
                customStyle={detail.customStyle}
              />
              <div>{detail.desc}</div>
              <Button
                href={detail.link}
                onClick={() =>
                  sendAnalyticsEvent(HOMEPAGE_CLICK_BROWSE, {section: detail.analyticsName})
                }
              >
                {detail.buttonName}
              </Button>
            </Card>
          ))}
        </DetailsDiv>
      </SubContainer>
    </Container>
  );
};

BrowseStackshare.propTypes = {
  sendAnalyticsEvent: PropTypes.func
};

export default BrowseStackshare;
