import React from 'react';
import {withAnalyticsPayload} from '../../../shared/enhancers/analytics-enhancer';
import {compose} from 'react-apollo';
import LandingComponent from './landing-component';

const Landing = () => {
  return <LandingComponent />;
};

export default compose(
  withAnalyticsPayload({
    'page.name': 'Stackshare Home',
    path: typeof window !== 'undefined' ? window.location.pathname : null,
    url: typeof window !== 'undefined' ? window.location.href : null,
    referrer: typeof document !== 'undefined' ? document.referrer : null
  })
)(Landing);
