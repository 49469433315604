export const sections = [
  {
    title: 'Comparisons',
    items: [
      {
        title: 'Bitbucket vs GitLab',
        path: '/stackups/bitbucket-vs-gitlab'
      },
      {
        title: 'npm vs Yarn',
        path: 'stackups/npm-vs-yarn'
      },
      {
        title: 'Ansible vs Jenkins',
        path: '/stackups/ansible-vs-jenkins'
      },
      {
        title: 'Grafana vs Kibana',
        path: '/stackups/grafana-vs-kibana'
      },
      {
        title: 'Jest vs Mocha',
        path: '/stackups/jest-vs-mocha'
      }
    ]
  },
  {
    title: 'Tools',
    items: [
      {title: 'Docker', path: '/docker'},
      {
        title: 'AngularJS',
        path: '/angularjs'
      },
      {title: 'Redis', path: '/redis'},
      {title: 'Postman', path: '/postman'},
      {title: 'React', path: '/react'}
    ]
  },
  {
    title: 'Alternatives',
    items: [
      {title: 'ActiveMQ', path: '/activemq/alternatives'},
      {title: 'Postman', path: '/postman/alternatives'},
      {title: 'Firebase', path: '/firebase/alternatives'},
      {title: 'Kafka', path: '/kafka/alternatives'},
      {title: 'SonarQube', path: '/sonarqube/alternatives'}
    ]
  },
  {
    title: 'Company Stacks',
    items: [
      {
        title: 'Stack Overflow',
        path: '/stack-exchange/stack-overflow'
      },
      {
        title: 'Digital Ocean',
        path: '/digitalocean/digitalocean'
      },
      {title: 'Airbnb', path: '/airbnb/airbnb'},
      {
        title: 'Product Hunt',
        path: '/product-hunt/product-hunt'
      },
      {title: 'Uber', path: '/uber-technologies/uber'}
    ]
  }
];
